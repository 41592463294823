import React from "react";
import {
  EditRounded,
  LocationOff,
  RemoveRedEye,
  FiberManualRecord,
  OfflineBolt,
  AddCircle,
} from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import { Grid, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { headRowsConfigGestor, headRowsGestor } from "../constants/headRows";
import ObjectTable from "../default/table/ObjectTable";
import {
  makeActionFormDialog,
  makeAlertDialog,
} from "../default/dialogs/DialogRefInterface";
import FormGestor from "./FormGestor";
import { gestorService } from "../services/gestor_service";
import { validateShowResponseToast, validRequiredFields } from "../helpers/toast_alerts";
import { colors } from "../constants/dc_constants";
import ShowGestor from "./ShowGestor";
import { checkOnline } from "../util/gestor_utils";
import {
  requiredAbbFields,
  requiredAbntFields,
  requiredAbsFields,
  requiredMardeyFields,
  requiredPowerFields
} from "../constants/equipmentConstants";

class ConfigGestor extends React.Component {
  constructor(props) {
    super(props);
    var user = JSON.parse(localStorage.getItem("user"));
    var permission = user["permissao"];

    this.state = {
      rows: [],
      online: [],
      headRows: headRowsGestor,
      hasEditPermission: permission >= 3,
      statusEquipment: "1"
    };
  }

  getGestores = (statusEquipment) => {
    gestorService.getGestores("power",{statusEquipments: statusEquipment ?? this.state.statusEquipment}, (response) => {
      if (validateShowResponseToast(response, "", false)) {
        this.setState({
          rows: response.data,
          online: response.data.coletas,
        });
      }
    });
  };

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });

    this.getGestores(value);
  }

  componentDidMount() {
    const newHeadRows = [
      ...headRowsConfigGestor,
      {
        id: "status",
        disablePadding: true,
        label: "Status Conexão",
        type: "custom_row",
        handleCustom: (rowValue) => {
          if (!rowValue.coletas[0]) {
            return (
              <Tooltip title="Sem dados" value={rowValue.status}>
                <FiberManualRecord style={{ color: colors.GREY }} />
              </Tooltip>
            );
          }

          if (checkOnline(rowValue.coletas[0])) {
            return (
              <Tooltip title="Online" value={rowValue.status}>
                <FiberManualRecord style={{ color: colors.GREEN }} />
              </Tooltip>
            );
          }

          return (
            <Tooltip title="Offline" value={rowValue.status}>
              <FiberManualRecord style={{ color: colors.RED }} />
            </Tooltip>
          );
        },
      },
    ];

    if(this.state.hasEditPermission) {
      this.actions.push({
        title: "Editar",
        label: "Editar",
        color: colors.GREEN,
        MaterialIcon: EditRounded,
        handleClick: (e, selected) => {
          e.stopPropagation();
          makeActionFormDialog(
            FormGestor,
            "Editar Medidor",
            null,
            selected,
            "Salvar",
            (data) => {
              let requiredFields = requiredPowerFields;
              requiredFields = requiredFields.concat(data.equipamento == "ABNT" ? requiredAbntFields : requiredAbbFields);
              requiredFields = requiredFields.concat(data.gateway == "ABS" ? requiredAbsFields : requiredMardeyFields);
              if (validRequiredFields(data, requiredFields)) {
                gestorService.updateGestor(data, (response) => {
                  if (validateShowResponseToast(response)) {
                    this.getGestores();
                  }
                });
              }
            }
          ).current.open();
        },
      });

      this.actions.push({
        title: "Ativar/Desativar",
        label: "Ativar/Desativar",
        color: colors.GREEN,
        MaterialIcon: LocationOff,
        handleClick: (e, selected) => {
          e.stopPropagation();
          makeAlertDialog(
            () => {
              const data = { id: selected.id };
              gestorService.deleteGestor(data, (response) => {
                if (validateShowResponseToast(response)) {
                  this.getGestores();
                }
              });
            },
            "Atenção",
            <>
              Deseja realmente {selected.status === 1 ? "desativar" : "ativar"} este medidor?
              <br />
              <strong>{selected.id_equipamento} - {selected.fazenda}</strong>
            </>
          ).current.open();
        },
      });
    };

    this.setState({
      headRows: newHeadRows,
    });

    this.getGestores();
  }

  selectedToolActions = [];

  toolActions = [
    {
      title: "Adicionar",
      label: "Adicionar",
      color: colors.ORANGE_POWER,
      MaterialIcon: AddCircle,
      handleClick: (e, selected) => {
        e.stopPropagation();
        makeActionFormDialog(
          FormGestor,
          "Adicionar Medidor",
          false,
          null,
          "Salvar",
          (data) => {
            let requiredFields = requiredPowerFields;
            requiredFields = requiredFields.concat(data.equipamento == "ABNT" ? requiredAbntFields : requiredAbbFields);
            requiredFields = requiredFields.concat(data.gateway == "ABS" ? requiredAbsFields : requiredMardeyFields);
            if (validRequiredFields(data, requiredFields)) {
              gestorService.createGestor(data, (response) => {
                if (validateShowResponseToast(response, "Gestor")) {
                  this.getGestores();
                }
              });
            }
          }
        ).current.open();
      },
    },
  ];

  actions = [
    {
      title: "Ver",
      label: "Ver",
      color: colors.GREEN,
      MaterialIcon: RemoveRedEye,
      handleClick: (e, selected) => {
        e.stopPropagation();
        makeActionFormDialog(
          ShowGestor,
          "Dados do Medidor",
          false,
          selected,
          "",
          () => {}
        ).current.open();
      },
    }
  ];

  iconHead = [
    {
      MaterialIcon: OfflineBolt,
      color: colors.ORANGE_POWER,
    },
  ];

  render() {
    return (
      <>
        {
          this.state.hasEditPermission && (
            <Grid container style={{marginBottom: 20}}>
              <Grid item md={10}></Grid>
              <Grid item md={2}>
                <FormControl fullWidth>
                  <InputLabel>Mostrar equipamentos</InputLabel>
                  <Select
                    fullWidth
                    value={this.state.statusEquipment}
                    name="statusEquipment"
                    onChange={this.handleChange}
                    style={{backgroundColor: "#F7F7F8"}}
                  >
                    <MenuItem value={1}>Ativos</MenuItem>
                    <MenuItem value={0}>Inativos</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )
        }

        <ObjectTable
          rowId="id"
          tableName="Cadastros"
          materialIconHead={this.iconHead}
          headRows={this.state.headRows}
          actions={this.actions}
          selectedToolActions={this.selectedToolActions}
          toolActions={this.state.hasEditPermission ? this.toolActions : []}
          rowSize={40}
          rowsOptions={[20, 40, 80]}
          dense={true}
          rows={this.state.rows || []}
        />
    </>

    );
  }
}

export default ConfigGestor;