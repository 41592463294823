const oneHourValueInterval = 4;

export const INTERVALOS = [
  { value: oneHourValueInterval - 1, desc: `1 hora (${oneHourValueInterval} registros)` },
  { value: (oneHourValueInterval * 3) - 1, desc: `3 horas (${oneHourValueInterval * 3} registros)` },
  { value: (oneHourValueInterval * 6) - 1, desc: `6 horas (${oneHourValueInterval * 6} registros)`  },
  { value: (oneHourValueInterval * 12) - 1, desc: `12 horas (${oneHourValueInterval * 12} registros)`  },
  { value: (oneHourValueInterval * 24) - 1, desc: `1 dia (${oneHourValueInterval * 24} registros)`  },
  { value: (oneHourValueInterval * 72) - 1, desc: `3 dias (${oneHourValueInterval * 72} registros)`  },
];

export const colors = {
  PRIMARY: "#1a162c",
  SECONDARY: "#fafafa",
  GREY: "#A8A8A8",
  LIGHT_GREY: "#ececec",
  DARK_GREY: "#969696",
  GREEN: "#6AAD43",
  BLUE: "#029AA8",
  ORANGE: "orange",
  YELLOW: "yellow",
  RED: "red",
  WHITE: "#ffffff",
  ORANGE_POWER: "#F5761B",
  BLACK: "#000",
};

export const logStatus = {
  LOG_HIDE: 0,
  LOG_SUCCESS: 1,
  LOG_PARTIAL_SUCCESS: 2,
  LOG_FAIL: 3,
};

export const tensaoValues = {
  CRITICO: "Crítico",
  PRECARIO: "Precário",
  ADEQUADO: "Adequado",
};

export const authConstants = {
  GOOGLE_ID:
    "1057150613570-gh7ujdbmvmam9vugkg3in9ic7hio6ca8.apps.googleusercontent.com",
};

export const daysByMonth = [
  0,31,29,31,30,31,30,31,31,30,31,30,31
]