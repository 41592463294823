import React from "react";
import {
  EditRounded,
  LocationOff,
  BarChartRounded,
  ReceiptRounded,
  NetworkCheck,
  RemoveRedEye,
  FiberManualRecord,
  OfflineBolt,
  AddCircle,
  AccessTime,
} from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import { Grid, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { headRowsGestor } from "../constants/headRows";
import ObjectTable from "../default/table/ObjectTable";
import {
  makeActionFormDialog,
  makeAlertDialog,
} from "../default/dialogs/DialogRefInterface";
import FormGestor from "./FormGestor";
import AbsAbntRealTimeData from "./AbsAbntRealTimeData";
import ChartGestor from "../projectPower/_graficos/ChartGestor";
import TableRelatorioAneel from "./TableRelatorioAneel";
import FilterRelatorio from "./FilterRelatorio";
import TableRelatorioMassa from "./TableRelatorioMassa";
import { gestorService } from "../services/gestor_service";
import { validateShowResponseToast, validRequiredFields } from "../helpers/toast_alerts";
import { colors } from "../constants/dc_constants";
import ChartGestorOn from "../projectPower/_graficos/ChartGestorOn";
import ShowGestor from "./ShowGestor";
import { checkOnline, makeListGestorReport } from "../util/gestor_utils";
import {
  requiredAbbFields,
  requiredAbntFields,
  requiredAbsFields,
  requiredMardeyFields,
  requiredPowerFields
} from "../constants/equipmentConstants";
import Relatorios from "../projectPower/_relatorios";
import moment from "moment";

class TableGestor extends React.Component {
  constructor(props) {
    super(props);
    var user = JSON.parse(localStorage.getItem("user"));
    var permission = user["permissao"];

    this.state = {
      rows: [],
      online: [],
      headRows: headRowsGestor,
      hasEditPermission: permission >= 3,
      statusEquipment: "1"
    };
  }

  getGestores = () => {
    gestorService.getGestores("power", { statusEquipments: 1 }, async (response) => {
      if (validateShowResponseToast(response, "", false)) {
          const data_inicial = moment().startOf('month').format("YYYY-MM-DD");
          const data_final = moment().format("YYYY-MM-DD");
          for (const medidor of response.data) {
            const data = {
              gestorId: medidor.id,
              dataInicial: data_inicial,
              dataFinal: data_final,
            };

            await new Promise((resolve) => {
              gestorService.getRelatorioAneel(data, async (response) => {
                if (response && response.data) {
                  const reportData = await makeListGestorReport(medidor, response.data, data_inicial, data_final);
                  Object.assign(medidor, reportData);
                }
                resolve();
              });
            });

            this.setState((prevState) => ({
              rows: [...prevState.rows, medidor],
              online: [...(prevState.online || []), ...(medidor.coletas || [])],
            }));
          }
      }
    });
  };

  // handleChange = (event) => {
  //   const { name, value } = event.target;
  //   this.setState({ [name]: value });

  //   this.getGestores(value);
  // }

  componentDidMount() {
    const newHeadRows = [
      ...headRowsGestor,
      {
        id: "connection",
        disablePadding: true,
        label: "Conexão",
        type: "custom_row",
        handleCustom: (rowValue) => {
          if (rowValue.conexao == 1) {
            return (
              <Tooltip title="conectado" value={"conectado"}>
                <FiberManualRecord style={{ color: colors.GREEN }} />
              </Tooltip>
            );
          }
          else if (rowValue.conexao == 2) {
            return (
              <Tooltip title="ABB" value={"ABB"}>
                <FiberManualRecord style={{ color: colors.GREY }} />
              </Tooltip>
            );
          } else {
            return (
              <Tooltip title="desconectado" value={"desconectado"}>
              <FiberManualRecord style={{ color: colors.RED }} />
            </Tooltip>
          );
        }
        },
      },
      {
        id: "status",
        disablePadding: true,
        label: "Coletando",
        type: "custom_row",
        handleCustom: (rowValue) => {
          const dataHora = moment(rowValue?.coletas[0]?.data_coleta).format('DD/MM/YYYY HH:mm');
          if (!rowValue.coletas[0]) {
            return (
              <Tooltip title="Sem dados" value={rowValue.status}>
                <FiberManualRecord style={{ color: colors.GREY }} />
              </Tooltip>
            );
          }

          if (checkOnline(rowValue.coletas[0])) {
            return (
              <Tooltip title={dataHora} value={rowValue.status}>
                <FiberManualRecord style={{ color: colors.GREEN }} />
              </Tooltip>
            );
          }

          return (
            <Tooltip title={dataHora} value={rowValue.status}>
              <FiberManualRecord style={{ color: colors.RED }} />
            </Tooltip>
          );
        },
      },
      {
        id: "demandaComplementar",
        disablePadding: true,
        label: "Demanda complementar",
        type: "custom_row",
        handleCustom: (rowValue) => {
          if (rowValue.demandaComplementar == "atingido") {
            return (
              <Tooltip title={rowValue.demandaComplementar} value={rowValue.demandaComplementar}>
                <FiberManualRecord style={{ color: colors.GREEN }} />
              </Tooltip>
            );
          } else if (rowValue.demandaComplementar == "não atingido") {
            return (
              <Tooltip title={rowValue.demandaComplementar} value={rowValue.demandaComplementar}>
                <FiberManualRecord style={{ color: colors.YELLOW }} />
              </Tooltip>
            );
          } else if (rowValue.demandaComplementar == "urgente") {
            return (
              <Tooltip title={rowValue.demandaComplementar} value={rowValue.demandaComplementar}>
                <FiberManualRecord style={{ color: colors.RED }} />
              </Tooltip>
            );
          }
          else if (rowValue.demandaComplementar == "multa") {
            return (
              <Tooltip title={rowValue.demandaComplementar} value={rowValue.demandaComplementar}>
                <FiberManualRecord style={{ color: colors.BLACK }} />
              </Tooltip>
            );
          }
          return (
            <Tooltip title={rowValue.demandaComplementar} value={rowValue.demandaComplementar}>
              <FiberManualRecord style={{ color: colors.GREY }} />
            </Tooltip>
          );
        },
      },
    ];
    if (this.state.hasEditPermission) {
      this.actions.push({
        title: "Consulta em tempo real",
        label: "Consulta em tempo real",
        color: colors.GREEN,
        MaterialIcon: AccessTime,
        handleClick: (e, selected) => {
          e.stopPropagation();

          if (selected.equipamento === "ABNT") {
            makeActionFormDialog(
              AbsAbntRealTimeData,
              "Dados em tempo real",
              null,
              selected,
              null,
              null
            ).current.open();
          } else {
            makeAlertDialog(
              () => { },
              "Atenção",
              <>
                A consulta em tempo real está disponível apenas para medidores <strong>ABS-ABNT.</strong>
              </>
            ).current.open();
          }
        }
      });
    };

    this.setState({
      headRows: newHeadRows,
    });

    this.getGestores();
  }

  selectedToolActions = [];
  toolActions = [];

  actions = [
    {
      title: "Gráficos Online",
      color: colors.GREEN,
      MaterialIcon: NetworkCheck,
      handleClick: (e, selected) => {
        e.stopPropagation();
        makeActionFormDialog(
          ChartGestorOn,
          `Painel Online`,
          true,
          selected,
          null,
          null
        ).current.open();
      },
    },
    {
      title: "Ver Gráficos",
      label: "Ver",
      color: colors.GREEN,
      MaterialIcon: BarChartRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        makeActionFormDialog(
          ChartGestor,
          `Gráficos do Medidor: ${selected.id_equipamento} - ${selected.fazenda}`,
          true,
          selected,
          null,
          null
        ).current.open();
      },
    },
    {
      title: "Ver Relatório",
      label: "Ver",
      color: colors.GREEN,
      MaterialIcon: ReceiptRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();

        makeActionFormDialog(
          () => Relatorios(selected),
          `Relatorios do Medidor: ${selected.id_equipamento} - ${selected.fazenda}`,
          true,
          null,
          null,
          null,
        ).current.open();
      },
    },
    {
      title: "Ver",
      label: "Ver",
      color: colors.GREEN,
      MaterialIcon: RemoveRedEye,
      handleClick: (e, selected) => {
        e.stopPropagation();
        makeActionFormDialog(
          ShowGestor,
          "Dados do Medidor",
          false,
          selected,
          "",
          () => { }
        ).current.open();
      },
    }
  ];

  iconHead = [
    {
      MaterialIcon: OfflineBolt,
      color: colors.ORANGE_POWER,
    },
  ];

  render() {
    return (
      <>
        <ObjectTable
          rowId="id"
          tableName="Medidores"
          materialIconHead={this.iconHead}
          headRows={this.state.headRows}
          actions={this.actions}
          selectedToolActions={this.selectedToolActions}
          toolActions={this.state.hasEditPermission ? this.toolActions : []}
          rowSize={40}
          rowsOptions={[20, 40, 80]}
          dense={true}
          rows={this.state.rows || []}
        />
      </>

    );
  }
}

export default TableGestor;
