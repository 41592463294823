import styles from "./TelaDashboard.module.css"

function TelaDashboardPower() {
  return (
    <div>

      <h1>Tela Dashboard Power</h1>
      <div className={styles.container}>
        <div className={styles.dataContainer}>
          <div className={styles.containerTitle}>Conexão</div>
        </div>
        <div className={styles.dataContainer}>
          <div className={styles.containerTitle}>Erro Comunicação</div>
        </div>
        <div className={styles.dataContainer}>
          <div className={styles.containerTitle}>Potencial Economia</div>
        </div>
        <div className={styles.dataContainer}>
          <div className={styles.containerTitle}>Demanda Complementar</div>
        </div>
        <div className={styles.dataContainer}>
          <div className={styles.containerTitle}>Queda de energia</div>
        </div>
        <div className={styles.dataContainer}>
          <div className={styles.containerTitle}>UFER</div>
        </div>
      </div>
    </div>
  )
}
export default TelaDashboardPower;