import React from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ExitToAppSharp from "@material-ui/icons/ExitToAppSharp";
import { withRouter, Route, Router as BrowserRouter, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Tooltip from "@material-ui/core/Tooltip";
import ProgressDialog from "../default/dialogs/ProgressDialog";
import ObjectListDialog from "../default/dialogs/ObjectListDialog";
import {
  makeAlertDialog,
  makeProgressDialog,
  makeObjectListDialog,
  makeActionFormDialog,
  makeActionFormDialog2,
} from "../default/dialogs/DialogRefInterface";
import { MainListItems } from "./ListItems";
import { userService } from "../services/user_service";
import ActionFormDialog from "../default/dialogs/ActionFormDialog";
import { Grid } from "@material-ui/core";
import TableGestor from "../_medidor/TableGestor";
import TableUsuario from "../_usuario/TableUsuario";
import { colors } from "../constants/dc_constants";
import Logs from "./Logs";
import MapaGestor from "../_medidor/MapaGestor";
import TelaFechamento from "../projectPower/_fechamento/TelaFechamento";
import TableResumoFechamento from "../projectPower/_fechamento/TableResumoFechamento";
import TelaEconomia from "../projectPower/_economia/TelaEconomia";
import TelaCharts from "../projectPower/_graficos/TelaCharts";
import TelaChartsWater from "../projectWater/_graficos/TelaCharts";
import browserHistory from "../helpers/browser_history.js";
import TableGestorWater from "../_medidor/TableGestorWater";

import Esqueleton from "../_medidor/Esqueleton";
import TelaAlertas from "../projectPower/_alertas/TelaAlertas";
import TelaAlertasWater from "../projectWater/_alertas/TelaAlertasWater";
import Relatorios from "../projectPower/_relatorios";
import RelatoriosWater from "../projectWater/_relatorios";
import TableConcessionaria from "../projectPower/_concessionarias/TableConcessionaria.jsx";
import TelaDashboard from "../projectWater/_dashboard/TelaDashboard.jsx";
import TelaDashboardPower from "../projectPower/_dashboard/TelaDashboard.jsx";
import ConfigGestor from "../_medidor/ConfigGestor.jsx";

const drawerWidth = 250;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 20,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "10 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: "none",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    backgroundColor: colors.PRIMARY,
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {},
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  tooltip: {
    color: "#f1f1f1",
    rippleBackgroundColor: "blue",
  },
});

function handleOpenMenu(value){
  return {
    type: 'DBA_DASHBOARD_OPEN_MENU',
    payload: {open: value}
  }
}

function handleOpenSettings(value){
  return {
    type: 'DBA_DASHBOARD_OPEN_SETTINGS',
    payload: {openSettings: value}
  }
}

function NotFoundPage() {
  return <h2> 404 - Página Não Encontrada!</h2>;
}

function Gestores() {
  return (
    <div style={{ margin: 20 }}>
      <TableGestor browserHistory={browserHistory}></TableGestor>
    </div>
  );
}

function GestoresAgua() {
  return (
    <div style={{ margin: 20}}>
      <TableGestorWater BrowserRouter={browserHistory}></TableGestorWater>
    </div>
  )
}

function Usuarios() {
  return (
    <div style={{ margin: 20 }}>
      <TableUsuario browserHistory={browserHistory}></TableUsuario>
    </div>
  );
}

function TelaLogs() {
  return (
    <div style={{ margin: 20 }}>
      <Logs />
    </div>
  );
}

function Fechamento() {
  return (
    <div style={{ margin: 20 }}>
      <TelaFechamento />
    </div>
  );
}

function Relatorio() {
  return (
    <div style={{ margin: 20 }}>
      <Relatorios />
    </div>
  );
}

function RelatorioAgua() {
  return (
    <div style={{ margin: 20 }}>
      <RelatoriosWater />
    </div>
  );
}

function TableResumo() {
  return (
    <div style={{ margin: 20 }}>
      <TableResumoFechamento />
    </div>
  );
}

function TelaGraficos() {
  return (
    <div style={{ margin: 20 }}>
      <TelaCharts />
    </div>
  );
}

function TelaGraficosWater() {
  return (
    <div style={{ margin: 20 }}>
      <TelaChartsWater />
    </div>
  );
}

function Economia() {
  return (
    <div style={{ margin: 20 }}>
      <TelaEconomia />
    </div>
  );
}

function Alertas() {
  return (
    <div style={{ margin: 20 }}>
      <TelaAlertas />
    </div>
  );
}
function AlertasAgua() {
  return (
    <div style={{ margin: 20 }}>
      <TelaAlertasWater />
    </div>
  );
}

function Concessionarias(){
  return (
    <div style={{margin: 20}}>
      <TableConcessionaria browserHistory={browserHistory}></TableConcessionaria>
    </div>
  )
}

function DashboardAgua(){
  return (
    <div style={{margin: 20}}>
      <TelaDashboard browserHistory={browserHistory}></TelaDashboard>
    </div>
  )
}

function DashboardPower(){
  return (
    <div style={{margin: 20}}>
      <TelaDashboardPower browserHistory={browserHistory}></TelaDashboardPower>
    </div>
  )
}

function GestorRegister(){
  return (
    <div style={{margin: 20}}>
      <ConfigGestor browserHistory={browserHistory}></ConfigGestor>
    </div>
  )
}

class Dashboard extends React.Component {

  handleDrawerOpen() {
    setOpen(true);
  }

  handleDrawerClose() {
    setOpen(false);
  }

  handleAgree(event) {
    userService.logout();
    browserHistory.push("/");
  }

  handleLogout = () => {
    makeAlertDialog(
      this.handleAgree,
      "Atenção",
      "Deseja realmente sair da aplicação"
    ).current.open();
  };

  render() {
    const { classes } = this.props;
    const authData = JSON.parse(localStorage.getItem('user') || '{}');
    const firstName = authData.nome ? authData.nome.split(' ')[0] : 'Usuário';
    const permission = authData.permissao || '';

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, this.props.open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="Open drawer"
              // onClick={this.handleDrawerOpen}
              onClick={() => this.props.handleOpenMenu(true)}
              className={clsx(
                classes.menuButton,
                this.props.open && classes.menuButtonHidden
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              noWrap
              className={classes.title}
            ></Typography>
            <Typography variant="body1" style={{ marginLeft: 16, color: colors.GREY }}>
              Olá, {firstName}
            </Typography>
            <Tooltip title={<h3>Sair da Aplicação</h3>}>
              <IconButton style={{ color: colors.ORANGE_POWER }} onClick={this.handleLogout}>
                <ExitToAppSharp />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !this.props.open && classes.drawerPaperClose),
          }}
          open={this.props.open}
        >
          {this.props.open ? (
            <Grid container style={{ backgroundColor: colors.PRIMARY }}>
              <Grid item xs={10} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <img
                  alt="logo"
                  style={{ marginLeft: 10, marginTop: 13, maxWidth: 150 }}
                  width={"100%"}
                  src="/static/images/logo.png"
                ></img>
              </Grid>
              <Grid item xs={2}>
                <div className={classes.toolbarIcon}>
                  <IconButton onClick={() => this.props.handleOpenMenu(false)}>
                    <ChevronLeftIcon style={{ color: "white" }} />
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          ) : (
            <div className={classes.toolbarIcon}>
              <IconButton onClick={() => this.props.handleOpenMenu(false)}>
                <ChevronLeftIcon style={{ color: "white" }} />
              </IconButton>
            </div>
          )}

          <Divider />
          {/* <List>{MainListItems(browserHistory)}</List> */}
          <List>
            <MainListItems itemClickHandle={this.handleItemClick} browserHistory={browserHistory} />
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <div className={classes.container}>
            <ProgressDialog ref={makeProgressDialog()} />
            <ObjectListDialog ref={makeObjectListDialog()} />
            <ActionFormDialog ref={makeActionFormDialog()} />
            <ActionFormDialog ref={makeActionFormDialog2()} />

            <BrowserRouter history={browserHistory}>
              <Switch>
                <Route
                  exact
                  path="/dashboard"
                  render={(props) => <MapaGestor {...props} open={this.props.open} />}
                />
                <Route exact path="/dashboard/gestores" component={Gestores} />
                <Route exact path="/agua/gestores" component={GestoresAgua}/>
                <Route exact path="/dashboard/usuarios" component={Usuarios} />
                <Route exact path="/dashboard/logs" component={TelaLogs} />
                <Route exact path="/dashboard/economia" component={Economia} />
                <Route exact path="/dashboard/alertas" component={Alertas} />
                <Route exact path="/agua/alertas" component={AlertasAgua}/>
                <Route exact path="/dashboard/concessionarias" component={Concessionarias}/>
                <Route exact path="/agua/dashboard" component={DashboardAgua}/>
                <Route exact path="/power/dashboard" component={DashboardPower}/>
                <Route exact path="/dashboard/cadastros" component={GestorRegister}/>

                <Route
                  exact
                  path="/dashboard/esqueleton"
                  component={Esqueleton}
                />
                <Route
                  exact
                  path="/dashboard/fechamentos/novo"
                  component={Fechamento}
                />
                <Route
                  exact
                  path="/dashboard/fechamentos"
                  component={TableResumo}
                />
                <Route exact path="/dashboard/relatorios" component={Relatorio} />
                <Route exact path="/agua/relatorios" component={RelatorioAgua}/>
                <Route exact path="/dashboard/charts" component={TelaGraficos} />
                <Route exact path="/agua/charts" component={TelaGraficosWater} />
                <Route component={NotFoundPage} />
              </Switch>
            </BrowserRouter>
          </div>
        </main>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    open: state.dashboard.open,
    openSettings: state.dashboard.openSettings,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      handleOpenMenu,
      handleOpenSettings,
    },
    dispatch
  )
}

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard))
)
