import { ArrowForwardIos } from "@material-ui/icons";
import { ArrowBackIosNew } from "@mui/icons-material";
import moment from "moment";
import { useEffect, useState } from "react";


function MonthNavigator({ onMonthChange , type}) {
  const [date, setDate] = useState(moment().startOf(type));
  let formatDate = "MMMM";

  switch (type) {
    case "day":
      formatDate = "DD/MM/YYYY";
      break;
    case "month":
      formatDate = "MMMM";
      break;
    case "year":
      formatDate = "YYYY";
      break;
  }

  const handlePrevious = () => {
    setDate((prevDate) => (prevDate.clone().subtract(1, type)));
  };

  const handleNext = () => {
    setDate((prevDate) => (prevDate.clone().add(1, type)));
  };

  useEffect(() => {
    if (onMonthChange) {
      onMonthChange(date);
    }
  }, [date, onMonthChange]);

  return (
    <div style={styles.container}>
      <button onClick={handlePrevious} style={styles.button}><ArrowBackIosNew /> Anterior</button>
      <span style={styles.month}>{moment(date).format(formatDate).toUpperCase()}</span>
      <button onClick={handleNext} style={styles.button}><ArrowForwardIos /> Próximo</button>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    background: 'none',
    border: 'none',
    fontSize: '12px',
    color: '#6c7b88',
    cursor: 'pointer',
    padding: '10px',
    alignItems: 'inherit'
  },
  month: {
    width: '11rem',
    margin: '0 20px',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#6c7b88',
  },
};

export default MonthNavigator;