import React from "react";
import { Grid, Input, TextField, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Table, Thead, Tbody, Tr, Th } from "react-super-responsive-table";
import { colors, daysByMonth } from "../constants/dc_constants";
import { getMonthName } from "../util/utils";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import NumberFormat from "react-number-format";

const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
	},
	form: {
		minHeigth: 600,
		maxWidth: 775,
		minWidth: 775,
	},
	formControl: {
		minWidth: "220px",
	},
	container: {
		marginTop: "15px",
	},
	celHead: {
		marginBottom: 0,
		fontWeight: "bold",
		fontSize: 18,
		align: "center"
	},
	celBody: {
		marginBottom: 0,
		fontWeight: "bold",
		fontSize: 18,
		align: "",
	},
	th: {
		paddingInline: "0.3rem"
	}
});

class FormOutorga extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			outorga: [
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 1,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 2,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 3,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 4,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 5,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 6,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 7,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 8,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 9,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 10,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 11,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
				{
					id: 0,
					gestor_id: props.data.id,
					mes: 12,
					vazao: undefined,
					horas_dia: undefined,
					dias_mes: undefined,
					volume: undefined,
				},
			],
			unidade_vazao: props.data.unidade_vazao
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleFlowUnitChange = this.handleFlowUnitChange.bind(this);
		this.handleVolumeCalculation = this.handleVolumeCalculation.bind(this);
	}

	componentDidMount() {
		const data = this.props.data.outorgas || [];

		if (data.length > 0) {
			this.setState({
				outorga: data
			});
		}
	}

	handleVolumeCalculation(mes, unidade_vazao) {
		if(this.state.outorga[mes - 1]["horas_dia"]) {
			const horas_dia_str = (this.state.outorga[mes - 1]["horas_dia"]).split(":");
			const horas = horas_dia_str ? parseInt(horas_dia_str[0]) : 0;
			const minutos = horas_dia_str ? parseInt(horas_dia_str[1]) : 0;
			const horas_dia = (horas * 60) + minutos;

			const vazao = this.state.outorga[mes - 1]["vazao"] ?? 0;
			const dia_mes = this.state.outorga[mes - 1]["dias_mes"] ?? 0;

			const mh3 = vazao * horas_dia * dia_mes;
			const volume = (unidade_vazao === "l/s") ? mh3 * 0.06 : mh3 / 60;
			this.state.outorga[mes - 1]["volume"] = isNaN(volume) ? 0 : Math.round(volume);
		}
	}

	handleChange(e, mes) {
		let { name, value } = e.target;
		const unidade_vazao = this.state.unidade_vazao;
		const updatedOutorga = this.state.outorga;
		updatedOutorga[mes - 1][name] = value;

		this.handleVolumeCalculation(mes, unidade_vazao);

		this.setState({ outorga: updatedOutorga });
	}

	handleFlowUnitChange(e) {
		const unidade_vazao = e.target.value;

		this.setState({
			unidade_vazao: unidade_vazao
		});

		this.state.outorga.forEach((e) => {
			const mes = e.mes;
			this.handleVolumeCalculation(mes, unidade_vazao);
		});
	}

	action() {
    if (this.props.handleFormState) {
      this.props.handleFormState(this.state);
    }
  }

	render() {
		const { classes } = this.props;

		return (
			<form
				className={classes.form}
				onSubmit={(e) => {
					e.preventDefault();
				}}
			>
				<Grid
					container
					spacing={2}
					style={{
						border: `1px solid ${colors.LIGHT_GREY}`,
						borderRadius: 4,
						marginBottom: 10,
					}}
				>
					<Grid
						item
						xs={12}
						style={{
							display: "flex",
							alignItems: "center",
							gap: 6,
							borderBottom: `1px solid ${colors.LIGHT_GREY}`,
							padding: 10,
						}}
					>
						<ErrorOutlineOutlinedIcon
							style={{ color: colors.ORANGE_POWER }}
						/>
						Informe a unidade de medida da vazão:
						<FormControl>
							<RadioGroup
								row
								aria-labelledby="flow-unit"
								name="row-radio-buttons-group"
								value={this.state.unidade_vazao}
    						onChange={this.handleFlowUnitChange}
							>
								<FormControlLabel
									value="l/s"
									checked={this.state.unidade_vazao === 'l/s'}
									control={<Radio />}
									label="l/s"
								/>
								<FormControlLabel
									checked={this.state.unidade_vazao === 'm3/h'}
									value="m3/h"
									control={<Radio />}
									label="m3/h"
								/>
							</RadioGroup>
						</FormControl>
					</Grid>
					<Grid
						item
						xs={12}
						style={{
							padding: 20,
						}}
					>
						<Table
							style={{
								backgroundColor: colors.WHITE,
								fontSize: 14,
								width: "100%",
								color: colors.PRIMARY,
							}}
						>
							<Thead>
								<Tr>
									<Th></Th>
									<Th scope="col">
										<Typography
											className={classes.celHead}
										>
											Vazão
										</Typography>
									</Th>
									<Th scope="col">
										<Typography
											className={classes.celHead}
										>
											horas / dia
										</Typography>
									</Th>
									<Th scope="col">
										<Typography
											className={classes.celHead}
										>
											dias / mes
										</Typography>
									</Th>
									<Th scope="col">
										<Typography
											className={classes.celHead}
										>
											Volume
										</Typography>
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{
									this.state.outorga.map((mes) => {
										return (
											<Tr key={mes.mes}>
												<Th className={classes.th}>
													<Typography
														className={classes.celHead}
													>
														{getMonthName(mes.mes)}
													</Typography>
												</Th>
												<Th className={classes.th}>
													<TextField
														style={{width:"80%"}}
														id="vazao"
														name="vazao"
														type="number"
														inputProps={{ style: { textAlign: 'center' } }}
														value={mes.vazao}
														onChange={(e) => this.handleChange(e, mes.mes)}
													/>
												</Th>
												<Th>
													<NumberFormat
														id="horas_dia"
														name="horas_dia"
														fullWidth
														customInput={TextField}
														format={`##:##`}
														type="text"
														value={mes.horas_dia}
														onChange={(e) => this.handleChange(e, mes.mes)}
														InputProps={{
															inputProps: {
																style: {
																	textAlign: 'center',
																},
															},
														}}
													/>
												</Th>
												<Th className={classes.th}>
													<TextField
														style={{width:"80%"}}
														id="dias_mes"
														name="dias_mes"
														type="number"
														inputProps={{ style: { textAlign: 'center' } }}
														value={mes.dias_mes}
														onChange={(e) => {
															if(e.target.value >= 0 && e.target.value <= daysByMonth[mes.mes] ){
																this.handleChange(e, mes.mes)
															}
														}
														}
													/>
												</Th>
												<Th className={classes.th}>
													<div
														style={{
															minWidth: 140,
															backgroundColor: "#F5F5F5",
															textAlign: "center",
															padding: "6px 0 7px",
															height: 30,
															fontSize: "16px",
															borderBottom: "1px solid rgba(0, 0, 0, 0.42)"
														}}
														id="volume"
														onChange={(e) => this.handleChange(e, mes.mes)}
													>
														{mes.volume}
													</div>
												</Th>
											</Tr>
										)
									})
								}
							</Tbody>
						</Table>
					</Grid>
				</Grid>
			</form>
		)
	}
}

export default withStyles(styles)(FormOutorga);