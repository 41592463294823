import moment from "moment";
import { tensaoValues } from "../constants/dc_constants";
import { calculaEconomiaDiaria } from "../projectPower/_economia/relatorioEconomia";
import { coletaService } from "../services/coleta_service";
import { gestorService } from "../services/gestor_service";

const INDUTIVA = "Indutiva";
const CAPACITIVA = "Capacitiva";

/**
 *
 * @param {*} volts
 */
export function calculateTensaoIntervals(volts) {
  const t1 = volts * 0.86; // critico
  const t2 = volts * 0.91; // precario
  const t3 = volts * 1.04; // adequado
  const t4 = volts * 1.06; // precario
  const t5 = t4 + 200; // critico
  return [t1, t2, t3, t4, t5];
}

export function checkOnline(lastColeta) {
  try {
    const lastDate = lastColeta?.createdAt || null;

    return isOnlineDate(lastDate);
  } catch (error) {
    return false;
  }
}

export function isOnlineDate(lastDate) {
  const MAX_OFF_MINUTES = 60;
  try {
    const date = moment.utc(lastDate).format("YYYY-MM-DD HH:mm:ss");
    var duration = moment(new Date()).diff(moment(date), "minutes");

    return duration <= MAX_OFF_MINUTES;
  } catch (error) {
    return false;
  }
}

export function checkPump(lastDate, bomba_ligada) {
  const online = isOnlineDate(lastDate);
  if (online) {
    return bomba_ligada ? 1 : 0;
  } else return -1;
}

/* Define a cor do pontos no mapa de acordo com o status do Gestor
* online => verde
* offline => vermelho
* sem dados => cinza
*/

export function colorPin(isOnline) {
  switch (isOnline) {
    case true:
      return "#6AAD43";
    case false:
      return "#ad4343";
    default:
      return "#a8a8a8";
  }
}

/**
 *  tensaoValue: 380, intervals: [326.8, 345.8, 395.2, 402.8, 502.8]
 * @param {*} tensaoValue
 * @param {*} intervals
 */
export function findTensaoInterval(tensaoValue, intervals) {
  if (tensaoValue <= intervals[0]) return tensaoValues.CRITICO;
  else if (tensaoValue <= intervals[1]) return tensaoValues.PRECARIO;
  else if (tensaoValue <= intervals[2]) return tensaoValues.ADEQUADO;
  else if (tensaoValue <= intervals[3]) return tensaoValues.PRECARIO;
  else return tensaoValues.CRITICO;
}

/**
 *
 * @param {*} fator
 * @param {*} tipoFator
 */
export function getShowChartFatorPotencia(fator, tipoFator) {
  if (tipoFator === CAPACITIVA) {
    const labelValue = fator * -1;
    const fatorValue = fator + 2;
    return [labelValue, fatorValue];
  } else {
    return [fator, fator];
  }
}

/**
 *
 * @param {Float} value0 - Valor atual
 * @param {Float} value1 - Valor anterior
 * @param {Moment} time0 - Tempo atual
 * @param {Moment} time1 - Tempo anterior
 */
export function calculateKWh(value0, value1, time0, time1) {
  time0 = moment(time0);
  time1 = moment(time1);
  const diff = time0.diff(time1, "seconds");
  const value = (value0 - value1) / (1 / (3600 / diff));
  return Math.round(value * 100) / 100;
}

/**
 * @param {Float} kw - Energia Ativa kw
 * @param {Float} kwar - Energia reativa kvar
 */
export function calculateKWAparente(kw, kwar) {
  const result = Math.sqrt(kw ** 2 + kwar ** 2);
  return Math.round(result * 100) / 100;
}

/**
 *
 * @param {*} coleta0
 * @param {*} coleta1
 * @param {*} attr
 */
export function calculateKWFromColeta(
  coleta0,
  coleta1,
  attr = "energia_consumida_kw_acc"
) {
  try {
    const result = calculateKWh(
      coleta0[attr],
      coleta1[attr],
      coleta0.data_coleta,
      coleta1.data_coleta
    );
    return result;
  } catch (error) {
    return 0;
  }
}

/**
 *
 * @param {*} kw
 * @param {*} kwa
 */
export function calculateFatorPotencia(kw, kwa) {
  try {
    const result = parseFloat(kw / kwa).toFixed(2);
    return Number(result);
  } catch (error) {
    return 0;
  }
}

/**
 *
 * @param {*} gestor
 * @param {*} potenciaAtiva
 */
export function calculateAtivaDisponivel(gestor, potenciaAtiva) {
  try {
    const result = gestor.potencia_instalada - potenciaAtiva;
    return Math.round(result * 100) / 100;
  } catch (error) {
    return 0;
  }
}

/**
 *
 * @param {*} gestor
 * @param {*} potenciaAparente
 */
export function calculateAparenteDisponivel(gestor, potenciaAparente) {
  try {
    const result = gestor.potencia_transf - potenciaAparente;
    return Math.round(result * 100) / 100;
  } catch (error) {
    return 0;
  }
}

/**
 *
 * @param {*} gestor
 * @param {*} potenciaAtiva
 */
export function calculateFatorAtiva(gestor, potenciaAtiva) {
  try {
    const result = (potenciaAtiva * 100) / gestor.potencia_instalada;
    return Math.round(result * 100) / 100;
  } catch (error) {
    return 0;
  }
}

/**
 *
 * @param {*} gestor
 * @param {*} potenciaAparente
 */
export function calculateFatorAparente(gestor, potenciaAparente) {
  try {
    const result = (potenciaAparente * 100) / gestor.potencia_transf;
    return Math.round(result * 100) / 100;
  } catch (error) {
    return 0;
  }
}

/**
 *
 * @param {*} potenciaAtiva
 * @param {*} potenciaReativa
 */
export function makeTipoFatorPotencia(fatorPotencia) {
  let ret = "";
  if (fatorPotencia < 0) ret = CAPACITIVA;
  else if (fatorPotencia > 0) ret = INDUTIVA;
  return ret;
}

export function mediaTensao(tensaoL1, tensaoL2, tensaoL3) {
  return (Number(tensaoL1) + Number(tensaoL2) + Number(tensaoL3)) / 3;
}

export function mediaCorrente(correnteL1, correnteL2, correnteL3) {
  return (correnteL1 + correnteL2 + correnteL3) / 3;
}

export function buildResumoFechamento(data) {
  const resumoData = {
    kw_total: Math.round(
      data.reduce((pValue, cValue, index) => pValue + cValue.kw_total, 0)
    ),
    kw_injetado_total: Math.round(
      data.reduce((pValue, cValue, index) => pValue + cValue.kw_injetado_total, 0)
    ),
    kvar_total: Math.round(
      data.reduce((pValue, cValue, index) => pValue + cValue.kvar_total, 0)
    ),
    kw_ponta: Math.round(
      data.reduce((pValue, cValue, index) => pValue + cValue.kw_ponta, 0)
    ),
    kw_injetado_ponta: Math.round(
      data.reduce((pValue, cValue, index) => pValue + cValue.kw_injetado_ponta, 0)
    ),
    kw_fora_ponta: Math.round(
      data.reduce((pValue, cValue, index) => pValue + cValue.kw_fora_ponta, 0)
    ),
    kw_injetado_fora_ponta: Math.round(
      data.reduce((pValue, cValue, index) => pValue + cValue.kw_injetado_fora_ponta, 0)
    ),
    kw_reservado: Math.round(
      data.reduce((pValue, cValue, index) => pValue + cValue.kw_reservado, 0)
    ),
    kvar_ponta: Math.round(
      data.reduce((pValue, cValue, index) => pValue + cValue.kvar_ponta, 0)
    ),
    kvar_fora_ponta: Math.round(
      data.reduce((pValue, cValue, index) => pValue + cValue.kvar_fora_ponta, 0)
    ),
    kvar_reservado: Math.round(
      data.reduce((pValue, cValue, index) => pValue + cValue.kvar_reservado, 0)
    ),
    kw_hp_max: Math.round(Math.max(...data.map((o) => o.kw_hp_max), 0)),
    kw_hfp_max: Math.round(Math.max(...data.map((o) => o.kw_hfp_max), 0)),
    kw_hr_max: Math.round(Math.max(...data.map((o) => o.kw_hr_max), 0)),
    kw_injetado_max_ponta: Math.round(Math.max(...data.map((o) => o.kw_injetado_max_ponta), 0)),
    kw_injetado_max_fora_ponta: Math.round(Math.max(...data.map((o) => o.kw_injetado_max_fora_ponta), 0)),

    uferR: Math.round(
      data.reduce((pValue, cValue, index) => pValue + cValue.uferR, 0)),
    uferP: Math.round(
      data.reduce((pValue, cValue, index) => pValue + cValue.uferP, 0)),
    uferFP: Math.round(
      data.reduce((pValue, cValue, index) => pValue + cValue.uferFP, 0)),
    ufer_total: Math.round(
      data.reduce((pValue, cValue, index) => pValue + cValue.ufer_total, 0)),

    kvar_hp_max: Math.round(Math.max(...data.map((o) => o.kvar_hp_max), 0)),
    kvar_hfp_max: Math.round(Math.max(...data.map((o) => o.kvar_hfp_max), 0)),
    kvar_hr_max: Math.round(Math.max(...data.map((o) => o.kvar_hr_max), 0)),
    data_coleta: data.map((o) => o.data_coleta),
  };
  return resumoData;
}

export async function makeListGestorReport(gestor, data, dataInicial, dataFinal) {
  const tarifas = gestor.concessionaria;

  let numeroQuedas = 0;
  let currentSemEnergia = false;

  let consumoPonta = 0;

  let consumoPontaRs = 0;
  let consumoForaPontaRs = 0;
  let consumoReservadoRs = 0;
  let demandaReativaExcedenteRs = 0;
  let demandaAtivaRs = 0;
  let ultrapassagemRs = 0;
  let uferRs = 0;

  let statusDemandaAnual = "sem dados";

  let statusConexao = 0;

  data.forEach(coleta => {
    //quedas de energia
    if (!currentSemEnergia && coleta.sem_energia) {
      numeroQuedas++;
    }
    currentSemEnergia = coleta.sem_energia

    //consumo ponta
    if (coleta.posto_horario == "P") {
      consumoPonta += coleta.energia_consumida;
      consumoPontaRs += coleta.energia_consumida;
    }

    //Ufer
    else if (coleta.posto_horario == "F") {
      consumoForaPontaRs += coleta.energia_consumida;
    }
    else if (coleta.posto_horario == "R") {
      consumoReservadoRs += coleta.energia_consumida;
    }
    if (demandaAtivaRs < coleta.potencia_ativa) {
      demandaAtivaRs = coleta.potencia_ativa;
    }
    if (demandaReativaExcedenteRs < coleta.potencia_reativa_indutiva) {
      demandaReativaExcedenteRs = coleta.potencia_reativa_indutiva;
    }
    uferRs += coleta.ufer
  });

  //Ufer
  if (demandaAtivaRs >= demandaReativaExcedenteRs) {
    demandaReativaExcedenteRs = 0;
  } else {
    demandaReativaExcedenteRs = demandaReativaExcedenteRs - demandaAtivaRs;
  }
  if (demandaAtivaRs > gestor.demanda_contratada * 1.05) {
    ultrapassagemRs = demandaAtivaRs - gestor.demanda_contratada;
  }
  uferRs = uferRs.toFixed() * tarifas.tarifa_hfp_reativo;
  let fatura =
    ultrapassagemRs.toFixed() * tarifas.tarifa_ultrapassagem +
    demandaAtivaRs.toFixed() * tarifas.tarifa_demanda_ativa +
    demandaReativaExcedenteRs.toFixed() * tarifas.tarifa_demanda_reativa +
    consumoPontaRs.toFixed() * tarifas.tarifa_hp +
    consumoForaPontaRs.toFixed() * tarifas.tarifa_hfp +
    consumoReservadoRs.toFixed() * tarifas.tarifa_hr +
    uferRs
    || 1;

  //Potencial de economia
  const economiaDiaria = calculaEconomiaDiaria(gestor, data, dataInicial, dataFinal);
  const totalEconomia = economiaDiaria.reduce(
    (acc, value) => acc + value.potencial_economia,
    0
  ).toFixed(2);

  //Demanda complementar
  if (gestor.equipamento == "ABNT" && gestor.mes_fechamento) {
    const ano = moment().isAfter(moment().month(gestor.mes_fechamento - 1).startOf('month')) ?
      moment().year() : moment().year() - 1;
    const queryAnual = {
      gestor_id: gestor.id,
      year: ano,
      month: gestor.mes_fechamento,
    };
    await coletaService.getDemandaMensal(queryAnual, (response) => {
      if (response.status === 200 && response.data.length > 0) {
        const demandasMensais = response.data;
        const demandasRestantes = 3 - demandasMensais.filter(
          demanda => demanda.max4 >= gestor.demanda_contratada
        ).length;
        const mesAtual = moment().month() + 1;
        let mesesRestantes;
        if (gestor.mes_fechamento >= mesAtual) {
          mesesRestantes = gestor.mes_fechamento - mesAtual;
        } else {
          mesesRestantes = 12 - mesAtual + gestor.mes_fechamento;
        }
        if (demandasRestantes <= 0) statusDemandaAnual = "atingido"; //Verde
        else if (demandasRestantes < mesesRestantes) statusDemandaAnual = "não atingido"; //Amarelo
        else if (demandasRestantes == mesesRestantes) statusDemandaAnual = "urgente"; //Vermelho
        else if (demandasRestantes > mesesRestantes) statusDemandaAnual = "multa"; //Preto
      }
    })
  } else if (gestor.equipamento == "ABB") statusDemandaAnual = "ABB"

  //Conexão
  if (!checkOnline(gestor.coletas[0])) {
    if (gestor.equipamento == "ABNT") {
      const data = {
        modbusHost: gestor.host_equip,
        modbusPort: gestor.host_port,
        rtp: gestor.relacao_tp,
        rtc: gestor.relacao_tc,
        ke: gestor.constante_eletronica,
      }
      await gestorService.absAbntRealTimeData(data, (response) => {
        if (response.data && !(typeof response.data === "string")) {
          statusConexao = 1;
        }
      });
    } else {
      statusConexao = 2;
    }
  } else { statusConexao = 1 }

  const report = {
    numeroQuedas: numeroQuedas,
    potencialEconomia: "R$" + totalEconomia,
    consumoPonta: "R$" + (Math.floor(consumoPonta) * tarifas.tarifa_hp).toFixed(2),
    ufer: ((uferRs * 100) / fatura).toFixed() + "%",
    conexao: statusConexao,
    demandaComplementar: statusDemandaAnual,
  }
  return report
}