import React from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { colors } from '../constants/dc_constants';
import { coletaService } from '../services/coleta_service';
import { gestorService } from '../services/gestor_service';
import ExportXLS from '../default/templete_xls/ExportXlsx';
import CustomDateTimePicker from '../default/custom/CustomDateTimePicker';

export class RelatorioModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateRange: '',
      customStartDate: new Date(),
      customEndDate: new Date(),
      statusEquipment: '1',
      equipments: [],
      isLoading: false,
    };
  }

  handleChange = (event) => {
    const selectedRange = event.target.value;
    this.setState({ dateRange: selectedRange });

    const today = new Date();
    let startDate;
    const endDate = new Date(today);

    switch (selectedRange) {
      case 'lastMonth':
        startDate = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          today.getDate(),
        );

        break;
      case 'last3Months':
        startDate = new Date(
          today.getFullYear(),
          today.getMonth() - 3,
          today.getDate(),
        );

        break;
      case 'lastYear':
        startDate = new Date(
          today.getFullYear() - 1,
          today.getMonth(),
          today.getDate(),
        );

        break;
      default:
        break;
    }

    this.setState({
      customStartDate: startDate || this.state.customStartDate,
      customEndDate: endDate || this.state.customEndDate,
    });
  };

  handleCustomDateChange = (field) => (event) => {
    this.setState({ [field]: event.target.value });
  };

  handleExport = async () => {
    this.setState({ isLoading: true });
    const { customStartDate, customEndDate } = this.state;
    try {
      const authData = JSON.parse(localStorage.getItem('user') || '{}');
      const gestorId = authData.id;

      const data = {
        gestor_id: gestorId,
        data_inicial: customStartDate,
        data_final: customEndDate,
        attr: '',
      };

      const equipments = await this.getGestores();
      const allData = {
        Diário: [],
        Mensal: [],
        Semanal: [],
        'Não Agrupado': [],
      };
      for (const equipment of equipments) {
        const responseData = await this.getEquipmentData({
          ...data,
          gestor_id: equipment.id,
        });

        const headers = {
          Fazenda: equipment.fazenda,
          Latitude: equipment.lat,
          Longitude: equipment.long,
          UnidadeVazao: equipment.unidade_vazao,
          TipoEquipamento: equipment.equipamento,
        };

        allData['Diário'].push(
          ...this.createDailyData(responseData.groupedByDay, headers),
        );
        allData.Mensal.push(
          ...this.createMonthlyData(responseData.groupedByMonth, headers),
        );
        allData.Semanal.push(
          ...this.createWeeklyData(responseData.groupedByWeek, headers),
        );
        allData['Não Agrupado'].push(
          ...this.createUngroupedData(responseData.ungrouped, headers),
        );
      }

      ExportXLS(
        allData,
        `Relatorio_Fiscal_${authData.nome}`,
        'relatorio_fiscal',
      );
    } catch (error) {
      console.error('Erro ao exportar:', error);
    } finally {
      this.setState({ isLoading: false });
    }
    this.props.onClose();
  };

  createDailyData = (data, headers) => [
    { Data: `Fazenda: ${headers.Fazenda}` },
    { Data: `Latitude: ${headers.Latitude}` },
    { Data: `Longitude: ${headers.Longitude}` },
    { Data: `Tipo Equipamento: ${headers.TipoEquipamento}` },
    { Data: `Unidade Vazão: ${headers.UnidadeVazao}` },
    { Data: 'Data', Consumo: 'Consumo' },
    ...data.map(({ date, consumptionSum }) => ({
      Data: date,
      Consumo: consumptionSum,
    })),
    { Data: '' },
  ];

  createMonthlyData = (data, headers) => [
    { Data_Inicial: `Fazenda: ${headers.Fazenda}` },
    { Data_Inicial: `Latitude: ${headers.Latitude}` },
    { Data_Inicial: `Longitude: ${headers.Longitude}` },
    { Data_Inicial: `Tipo Equipamento: ${headers.TipoEquipamento}` },
    { Data_Inicial: `Unidade Vazão: ${headers.UnidadeVazao}` },
    {
      Data_Inicial: 'Data_Inicial',
      Data_Final: 'Data_Final',
      Consumo: 'Consumo',
    },
    ...data.map(({ startDate, endDate, consumptionSum }) => ({
      Data_Inicial: startDate,
      Data_Final: endDate,
      Consumo: consumptionSum,
    })),
    { Data_Inicial: '' },
  ];

  createWeeklyData = (data, headers) => [
    { Data_Inicial: `Fazenda: ${headers.Fazenda}` },
    { Data_Inicial: `Latitude: ${headers.Latitude}` },
    { Data_Inicial: `Longitude: ${headers.Longitude}` },
    { Data_Inicial: `Tipo Equipamento: ${headers.TipoEquipamento}` },
    { Data_Inicial: `Unidade Vazão: ${headers.UnidadeVazao}` },
    {
      Data_Inicial: 'Data_Inicial',
      Data_Final: 'Data_Final',
      Consumo: 'Consumo',
    },
    ...data.map(({ startDate, endDate, consumptionSum }) => ({
      Data_Inicial: startDate,
      Data_Final: endDate,
      Consumo: consumptionSum,
    })),
    { Data_Inicial: '' },
  ];

  createUngroupedData = (data, headers) => [
    { ID: `Fazenda: ${headers.Fazenda}` },
    { ID: `Latitude: ${headers.Latitude}` },
    { ID: `Longitude: ${headers.Longitude}` },
    { ID: `Tipo Equipamento: ${headers.TipoEquipamento}` },
    { ID: `Unidade Vazão: ${headers.UnidadeVazao}` },
    {
      ID: 'ID',
      Gestor_ID: 'Gestor_ID',
      Data_Coleta: 'Data_Coleta',
      Consumo: 'Consumo',
      Bomba_Ligada: 'Bomba_Ligada',
      Consumo_Acc: 'Consumo_Acc',
      Criado_Em: 'Criado_Em',
      Atualizado_Em: 'Atualizado_Em',
    },
    ...data.map(
      ({
        id,
        gestor_id,
        data_coleta,
        consumo,
        bomba_ligada,
        consumo_acc,
        createdAt,
        updatedAt,
      }) => ({
        ID: id,
        Gestor_ID: gestor_id,
        Data_Coleta: data_coleta,
        Consumo: consumo,
        Bomba_Ligada: bomba_ligada,
        Consumo_Acc: consumo_acc,
        Criado_Em: createdAt,
        Atualizado_Em: updatedAt,
      }),
    ),
    { ID: '' },
  ];

  getEquipmentData = async (data) => {
    return new Promise((resolve, reject) => {
      coletaService.getColetasAgua(data, (response) => {
        if (response && response.data) {
          resolve(response.data);
        } else {
          reject(new Error('Dados da resposta estão indefinidos ou vazios.'));
        }
      });
    });
  };

  getGestores = async (statusEquipment) => {
    return new Promise((resolve, reject) => {
      gestorService.getGestores(
        'water',
        { statusEquipments: statusEquipment ?? this.state.statusEquipment },
        (response) => {
          this.setState({
            equipments: response.data,
          });
          resolve(response.data);
        },
        (error) => {
          reject(error);
        },
      );
    });
  };

  render() {
    const { open, onClose } = this.props;
    const { dateRange, isLoading } = this.state;

    return (
      <Modal
        open={open}
        onClose={onClose}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            outline: 'none',
            width: '450px',
          }}
        >
          <h2>Relatório Fiscal</h2>
          <FormControl fullWidth style={{ marginBottom: '20px' }}>
            <InputLabel>Selecione o período</InputLabel>
            <Select value={dateRange} onChange={this.handleChange}>
              <MenuItem value="lastMonth">Último Mês</MenuItem>
              <MenuItem value="last3Months">Últimos 3 Meses</MenuItem>
              <MenuItem value="lastYear">Último Ano</MenuItem>
              <MenuItem value="custom">Personalizado</MenuItem>
            </Select>
          </FormControl>

          {dateRange === 'custom' && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '20px',
              }}
            >
              <CustomDateTimePicker
                fullWidth
                variant="inline"
                iconcolor={`${colors.BLUE}`}
                format="DD/MM/YYYY HH:mm"
                name="dataInicial"
                value={this.state.customStartDate}
                onChange={this.handleCustomDateChange('customStartDate')}
                KeyboardButtonProps={{
                  'aria-label': 'Mudar data',
                }}
                style={{ marginRight: '10px' }}
              />
              <CustomDateTimePicker
                fullWidth
                variant="inline"
                iconcolor={`${colors.BLUE}`}
                format="DD/MM/YYYY HH:mm"
                name="dataFinal"
                value={this.state.customEndDate}
                onChange={this.handleCustomDateChange('customEndDate')}
                KeyboardButtonProps={{
                  'aria-label': 'Mudar data',
                }}
              />
            </div>
          )}
          <Button
            fullWidth
            variant="contained"
            style={{
              height: 40,
              backgroundColor: colors.BLUE,
              color: 'white',
              marginTop: 14,
              boxShadow: 'none',
            }}
            onClick={this.handleExport}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Exportar para Excel'
            )}
          </Button>
        </div>
      </Modal>
    );
  }
};