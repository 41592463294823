import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  TextField,
  Grid,
} from "@material-ui/core";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  form: {
    minHeigth: 600,
    maxWidth: 775,
    minWidth: 775,
  },
  formControl: {
    minWidth: "220px",
  },
  container: {
    marginTop: "15px",
  },
});

class FormConcessionaria extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      nome: "",
      regiao: "",
      tarifa_hp: '',
      tarifa_hfp: '',
      tarifa_hr: '',
      tarifa_demanda_ativa: '',
      tarifa_demanda_reativa: '',
      tarifa_hfp_reativo: '',
      tarifa_ultrapassagem: '',
      id_vision: '',
      formato_integracao: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount(){
    const data = this.props.data;
    if(data){
      this.setState({
        ...data
      })
    }
  }

  // Necessario em todos os form abertos em ActionFormDialog
  action() {
    if (this.props.handleFormState) {
      this.props.handleFormState(this.state);
    }
  }
  
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  render() {
    const { classes } = this.props;
    return (
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormControl
          variant="outlined"
          fullWidth
          className={classes.formControl}
        >
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={6} className={classes.container}>
              <TextField
                id="nome"
                label="Nome da Concessionária"
                name="nome"
                fullWidth
                type="text"
                value={this.state.nome}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={6} className={classes.container}>
              <TextField
                id="regiao"
                label="Região"
                name="regiao"
                fullWidth
                type="text"
                value={this.state.regiao}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
          </Grid>

          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={4} className={classes.container}>
              <TextField
                id="tarifa_hp"
                label="Tarifa HP"
                name="tarifa_hp"
                fullWidth
                type="number"
                value={this.state.tarifa_hp}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={4} className={classes.container}>
              <TextField
                id="tarifa_hfp"
                label="Tarifa HFP"
                name="tarifa_hfp"
                fullWidth
                type="number"
                value={this.state.tarifa_hfp}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={4} className={classes.container}>
              <TextField
                id="tarifa_hr"
                label="Tarifa HR"
                name="tarifa_hr"
                fullWidth
                type="number"
                value={this.state.tarifa_hr}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={3} className={classes.container}>
              <TextField
                id="tarifa_demanda_ativa"
                label="Tarifa Demanda Ativa"
                name="tarifa_demanda_ativa"
                fullWidth
                type="number"
                value={this.state.tarifa_demanda_ativa}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={3} className={classes.container}>
              <TextField
                id="tarifa_demanda_reativa"
                label="Tarifa Demanda Reativa"
                name="tarifa_demanda_reativa"
                fullWidth
                type="number"
                value={this.state.tarifa_demanda_reativa}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={3} className={classes.container}>
              <TextField
                id="tarifa_ultrapassagem"
                label="Tarifa Ultrapassagem"
                name="tarifa_ultrapassagem"
                fullWidth
                type="number"
                value={this.state.tarifa_ultrapassagem}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={3} className={classes.container}>
              <TextField
                id="tarifa_hfp_reativo"
                label="Tarifa Reativa"
                name="tarifa_hfp_reativo"
                fullWidth
                type="number"
                value={this.state.tarifa_hfp_reativo}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={3} className={classes.container}>
              <TextField
                id="id_vision"
                label="Id na Vision"
                name="id_vision"
                fullWidth
                type="number"
                value={this.state.id_vision}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={9} className={classes.container}>
              <TextField
                id="formato_integracao"
                label="Formato de integração"
                name="formato_integracao"
                fullWidth
                type="text"
                value={this.state.formato_integracao}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
          </Grid>
        </FormControl>
      </form>
    )
  }

}

export default withStyles(styles)(FormConcessionaria);