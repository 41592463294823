import React, { useRef } from 'react'
import {DialogContent, DialogActions, Button, DialogTitle, Dialog, Slide, IconButton, Box} from '@material-ui/core'
import {ClearRounded} from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { colors } from '../../constants/dc_constants'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

class ActionFormDialog extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			ObjectForm : this.props.ObjectForm ? this.props.ObjectForm : "<div/>",
			open : this.props.open ? this.props.open : false,
			title : this.props.title || "",
			fullScreen: false,
			centroProdutores : props.centroProdutores || [],
			btn_title : props.btn_title,
			handleAfterClose : this.props.handleAfterClose || undefined,
			handleAction : this.props.handleAction || undefined,
			handleFormState : this.props.handleFormState || undefined,
			width : this.props.width || undefined
		}
		this.handleClose = this.handleClose.bind(this)

		this.formRef = null;

		this.setFormRef = element => {
			this.formRef = element;
		};
	}

	update_ref_state(ObjectForm, title="", fullScreen=false, data={}, btn_title, handleFormState=undefined, width=undefined){
		this.setState({
			title:title,
			fullScreen: fullScreen,
			data: data,
			ObjectForm: ObjectForm,
			btn_title: btn_title,
			handleFormState : handleFormState || undefined,
			width : width || undefined
		})
	}

	handleClose(e){
			this.setState({open: false})
	}

	open(){
			this.setState({open: true})
	}

	action(){
		if(this.formRef){
			if(this.formRef.action){
				this.formRef.action(() => {
					this.setState({open: false})
				})
			}
		}
	}

	render(){
		const {classes} = this.props
		return (
			<Dialog
				maxWidth={false}
				fullScreen={this.state.fullScreen}
				disableEscapeKeyDown={false}
				TransitionComponent={Transition}
				open={this.state.open}
				onClose={this.handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description">

				<Box display="flex" flexDirection="row-reverse" bgcolor="background.paper">
					<Box>
						<DialogActions >
							<IconButton aria-label="Sair" onClick={this.handleClose}>
								<ClearRounded color="error"/>
							</IconButton>
						</DialogActions>
					</Box>
					<Box display="flex" justifyContent="center"  flexGrow={1}>
						<DialogTitle id="form-dialog-title">{
							`${this.state.title}`
						}
						</DialogTitle>
					</Box>
				</Box>

				<DialogContent className={classes.dialog}
					style={
						this.state.width &&
						{
							width: "calc(100vw - 64px)",
							maxWidth: `${this.state.width}px`,
							minWidth: "auto"
						}
					}
				>
				{ this.state.ObjectForm ?
					(<this.state.ObjectForm
						handleFormState={this.state.handleFormState}
						ref={this.setFormRef}
						data={this.state.data}
					/>) :
					(
						<div></div>
					)
				}
				</DialogContent>

				<DialogActions>
					{ this.state.btn_title ?
						<Button onClick={(e) => {this.action()}} style={{color: colors.GREEN}}>
							{this.state.btn_title}
						</Button> :
						<div></div>
					}
				</DialogActions>
			</Dialog>
		)
	}
}

const styles = (theme) => ({
	root: {
		backgroundColor: theme.palette.background.paper,
	},
	dialog: {
		minWidth: 350,
		minHeight: 100,
	}
})

export default withStyles(styles)(ActionFormDialog)
